<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      width="width:45rem"
      padding="padding:0 1.2rem"
      @submit="handleSubmit(submit)"
      :disabled-button="disableButton"
      submit="Save"
      @close="$emit('close'), (forms = [])"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple capitalize">
          {{ modalType }}
        </p>
      </template>
      <div>
        <table aria-label="table" class=" w-full mt-4">
          <thead>
            <tr>
              <th
                class=" uppercase"
                id="header"
                v-for="(header, index) in headers"
                :key="index"
              >
                <p>{{ header.title }}</p>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, i) in forms"
              :key="i"
              class="tableBody text-sm "
              style="height: 48px; "
            >
              <td class="w-1/4">
                <c-select
                  :options="
                    modalType === 'addition'
                      ? additionCategoryItems
                      : deductionCategoryItems
                  "
                  @onChange="handleCategoryChange($event, i)"
                  variant="w-full capitalize"
                  v-model="data.categorySlug"
                />
              </td>
              <td class="w-1/4">
                <c-text placeholder="--Enter--" v-model="data.name" />
              </td>
              <td class="w-1/4">
                <div class="wrapper disabled mt-2" v-if="data.disable">
                  {{ data.isTaxable === "true" ? "Yes" : "No" }}
                </div>
                <c-select
                  v-else
                  :options="taxableOptions"
                  variant="w-full"
                  v-model="data.isTaxable"
                />
              </td>
              <td class="w-1/4">
                <c-text
                  placeholder="--Enter--"
                  type="number"
                  v-model="data.amount"
                />
              </td>
              <td v-if="forms.length > 1">
                <Icon
                  size="s"
                  class="text-flame cursor-pointer"
                  @click.native="removeForm(i)"
                  icon-name="close"
                />
              </td>
            </tr>
            <tr class="h-7" />
            <tr class="my-4">
              <button class="flex ml-4" @click="addForm">
                <Icon size="s" icon-name="icon-plus" />
                <p class="ml-3 text-flame font-semibold">
                  Add
                </p>
              </button>
            </tr>
            <tr class="h-7" />

            <tr
              style="height: 52px; border-spacing: 15px 0; background: #F7F7FF !important;"
              class="mt-4 bg-ghostWhite"
            >
              <td class="font-bold text-lg text-darkPurple ">
                Total
              </td>
              <td />
              <td />
              <td class="font-bold">
                {{ convertToCurrency(totalAmount) }}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import "vue-select/dist/vue-select.css";

export default {
  name: "FilterPayrun",
  components: {
    ValidationObserver,
    RightSideBar: () => import("@/components/RightSideBar"),
    CSelect: () => import("@scelloo/cloudenly-ui/src/components/select"),
    CText: () => import("@scelloo/cloudenly-ui/src/components/text")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String,
      default: "addition"
    },
    userId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      forms: [],
      taxableOptions: [
        { name: "Yes", id: "true" },
        { name: "No", id: "false" }
      ],
      disableButton: false,
      additionCategoryItems: ["commission", "reimbursement", "bonus", "others"],
      deductionCategoryItems: ["penalty", "others"],
      headers: [
        { title: "Category" },
        { title: "Name in Payslip" },
        { title: "Taxable" },
        { title: "amount(₦)" }
      ]
    };
  },
  watch: {
    openModal() {
      if (this.openModal) {
        if (!this.forms.length) {
          this.addForm();
        }
      }
    }
  },
  computed: {
    totalAmount() {
      let result = 0;
      this.forms.forEach(item => {
        result += Number(item.amount);
      });
      return result;
    }
  },
  methods: {
    handleCategoryChange(event, index) {
      if (event === "reimbursement") {
        this.forms[index].isTaxable = "false";
        this.forms[index].disable = true;
      }
    },
    addForm() {
      this.forms.push({
        categorySlug: "",
        disable: false,
        name: "",
        isTaxable: null,
        amount: ""
      });
    },
    removeForm(index) {
      if (index > 0) this.forms.splice(index, 1);
    },
    submit() {
      let month; let year;

      this.disableButton = true;
      const payload = [];

      if (Object.keys(this.$route.query).length !== 0) {
        month = this.$route.query.month;
        year = this.$route.query.year;
      }
      else {
        month = this.$route.params.month;
        year = this.$route.params.year
      }

      this.forms.forEach(item => {
        payload.push({
          orgId: this.$AuthUser.orgId,
          userId: this.userId,
          createdBy: this.userId,
          month,
          year,
          categorySlug: item.categorySlug,
          type: this.modalType,
          name: item.name,
          categoryId: null,
          amount: item.amount,
          isTaxable: item.isTaxable === "true"
        });
      });
      this.$_addAdditionsDeductions(payload)
        .then(() => {
          this.forms = [];
          this.$emit("close");
          this.disableButton = false;
          this.$toasted.success(`${this.modalType} added successfully`, {
            duration: 3000
          });
        })
        .catch(error => {
          this.disableButton = false;
          this.$toasted.error(`${this.modalType} not added successfully`, {
            duration: 5000
          });
          throw new Error(error);
        });
    }
  },
  mounted() {
    this.addForm();
  }
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: white;
}
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
